import React, { FC } from 'react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { useSearchParams } from 'react-router-dom';
import SETTINGS from 'settings';

function shouldAllowRedirect(url: string): boolean {
  return !!(
    url !== 'about:blank' &&
    SETTINGS.IOS_REP_APP_PACKAGE_NAME &&
    url.startsWith(SETTINGS.IOS_REP_APP_PACKAGE_NAME)
  );
}

const Redirect: FC = () => {
  const [params] = useSearchParams();
  const redirectUrl = params.get('url') ?? '';
  const sanitizedUrl = sanitizeUrl(decodeURIComponent(redirectUrl));

  if (shouldAllowRedirect(sanitizedUrl)) {
    window.location.assign(sanitizedUrl);
  } else {
    window.location.assign('not-found');
  }

  return <>Redirecting...</>;
};

export default Redirect;
