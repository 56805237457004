const SETTINGS = {
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.REACT_APP_API_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  IOS_REP_APP_PACKAGE_NAME: process.env.REACT_APP_IOS_REP_APP_PACKAGE_NAME,
  IOS_WEB_LIB_MIN_VERSION: process.env.REACT_APP_IOS_WEB_LIB_MIN_VERSION,
  PERSONALIZATION_EVENT_KIND_NAME:
    process.env.REACT_APP_PERSONALIZATION_EVENT_KIND_NAME,
};

export default SETTINGS;
